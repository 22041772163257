import React from "react"
import { useMutation } from "@apollo/client"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Switch from "@material-ui/core/Switch"
import { useStyles } from "./styles-maint"
import { FormButtonSpinnerBase } from "../../components/Form"
import { ButtonSmallBase } from "../../components/Button"
import { ADMIN_SCORE_BRACKET_POOL_MUTATION } from "../queries"

const BracketPool = ({ pool, setSnackBarSuccessMessage, setSnackBarErrorMessage }) => {
  const classes = useStyles()

  const [scoreFullSeason, setScoreFullSeason] = React.useState(false)
  const [AdminScoreBracketPoolMutation, { loading: loadingScorePool }] = useMutation(ADMIN_SCORE_BRACKET_POOL_MUTATION)

  const scorePool = async (poolId: string, scoreFullSeason: boolean) => {
    try {
      AdminScoreBracketPoolMutation({
        variables: { poolId, scoreFullSeason },
      })
        .then((result) => {
          setSnackBarSuccessMessage(result.data?.adminScoreBracketPool)
        })
        .catch((error) => {
          setSnackBarErrorMessage(`Received error from mutation when trying to score bracket pool ${error.message}`)
        })
    } catch (error: any) {
      setSnackBarErrorMessage(`Received error when trying to score bracket pool ${error.message}`)
    }
  }

  if (/challenge/.test(pool.gameInstanceUid)) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
        <iframe src="https://giphy.com/embed/owRSsSHHoVYFa" width="480" height="360" frameBorder="0" allowFullScreen></iframe>
        <br />
        <span>NOPE - This is a challenge pool.</span>
      </div>
    )
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Score Pool : Re-run Standings
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Score Full Season</div>
            <Switch checked={scoreFullSeason} onChange={() => setScoreFullSeason(!scoreFullSeason)} value={scoreFullSeason} color={"primary"} />
          </div>
          <br />
          <FormButtonSpinnerBase
            isValid={true}
            as={ButtonSmallBase}
            isSubmitting={loadingScorePool}
            inert="Rerun Standings"
            onClick={(e) => {
              e.preventDefault()
              scorePool(pool.id, scoreFullSeason)
            }}
            disabled={loadingScorePool}
            status={""}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default BracketPool
