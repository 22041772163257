import React from "react"
import { useMutation } from "@apollo/client"
import { TextField } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { fromGlobalId } from "../../../common/global-ids"
import { ButtonSmallBase } from "../../components/Button"
import { FormButtonSpinnerBase } from "../../components/Form"
import DataTable from "../Database/DataTable"
import { ADMIN_ARCHIVE_POOL_MUTATION, ADMIN_RESTORE_POOL_MUTATION, ADMIN_SCORE_POOL_MUTATION } from "../queries"
import { useStyles } from "./styles-maint"
import { ADMIN_ADD_MEMBER_MUTATION } from "../../App/queries"
import { useForm } from "react-hook-form"
import InfoCard from "./InfoCard"
import { extractValidationError, humanizeErrorMsg } from "../../../common/apiErrors"

const DefaultPool = ({ pool, setSnackBarSuccessMessage, setSnackBarErrorMessage, props }) => {
  const { id, name, isArchived } = pool

  const classes = useStyles()

  const scorePool = (poolId: string, AdminScorePool) => {
    return AdminScorePool({
      variables: { poolId },
    })
  }

  const archivePool = (poolId: string, AdminArchivePool) => {
    return AdminArchivePool({
      variables: { poolId },
    })
  }

  const restorePool = (poolId: string, AdminRestorePool) => {
    return AdminRestorePool({
      variables: { poolId },
    })
  }

  const adminAddMember = async (poolId: string, userLogin: string, custId: number, AdminAddMemberMutation) => {
    const result = AdminAddMemberMutation({
      variables: { poolId, userLogin, custId },
    })

    return result
  }

  const [AdminScorePool, { loading: loadingScorePool }] = useMutation(ADMIN_SCORE_POOL_MUTATION)
  const [AdminArchivePool, { loading: loadingArchivePool }] = useMutation(ADMIN_ARCHIVE_POOL_MUTATION)
  const [AdminRestorePool, { loading: loadingRestorePool }] = useMutation(ADMIN_RESTORE_POOL_MUTATION)
  const [AdminAddMemberMutation, { loading: loadingAddMember }] = useMutation(ADMIN_ADD_MEMBER_MUTATION)

  const { register, handleSubmit: handleSubmitUpsertEntry } = useForm()

  const onSubmitUpsertEntry = (data) => {
    const { userLogin, custId } = data
    adminAddMember(id, userLogin, custId, AdminAddMemberMutation)
      .then(() => setSnackBarSuccessMessage("Successfully added this member to the Pool. The page will automatically refresh soon!"))
      .then(() => window.setTimeout(() => window.location.reload(), 3000))
      .catch((err) => {
        const apiError = extractValidationError(err)
        const errorMessages = Object.keys(apiError.errors).map((key) => humanizeErrorMsg(key, apiError.errors[key]))
        const errorMessage = errorMessages.join(". ")

        setSnackBarErrorMessage(errorMessage)
        //setOpenErrorSnackbar(true)
        //handleErrorClick()
      })
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h3">
            Actions + Details
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Below will be all the Pool specific actions, with a breif description.
          </Typography>
          <Typography variant="h6" component="h6">
            Score Pool : Re-run Standings
          </Typography>

          <FormButtonSpinnerBase
            isValid={true}
            as={ButtonSmallBase}
            isSubmitting={loadingScorePool}
            inert="Rerun Standings"
            onClick={(e) => {
              e.preventDefault()
              scorePool(id, AdminScorePool).then((response) => {
                if (response.data.scorePoolJob.includes("No scoring")) {
                  setSnackBarErrorMessage(response.data.scorePoolJob)
                } else {
                  setSnackBarSuccessMessage(response.data.scorePoolJob)
                }
              })
            }}
            disabled={loadingScorePool}
            status={""}
          />
          <Typography variant="body2" component="div" color="textSecondary">
            <li>This action is used to re-score the pool for the whole season.</li>
            <li>This action is enabled only for CBS Admins only, and all Admins must be signed in prior.</li>
            <li>
              Have the option to force the process to score the Pool in the current process. Pools with large amounts of Entries are queued to be
              scored in background jobs..
            </li>
            <li>This action scores the full season, and not any specific period.</li>
          </Typography>

          {isArchived ? (
            <>
              <Typography variant="h6" component="h6">
                Restore Pool
              </Typography>
              <FormButtonSpinnerBase
                isValid={true}
                as={ButtonSmallBase}
                isSubmitting={loadingRestorePool}
                inert="Restore Pool"
                onClick={(e) => {
                  e.preventDefault()
                  restorePool(id, AdminRestorePool)
                    .then(setSnackBarSuccessMessage("Successfully restored this Pool. The page will automatically refresh soon!"))
                    .then(() => window.setTimeout(() => window.location.reload(), 3000))
                }}
                disabled={loadingRestorePool}
                status={""}
              />
              <Typography variant="body2" component="p" color="textSecondary">
                <li>This action fully restores the current pool.</li>
                <li>This action is enabled only for CBS Admins only, and all Admins must be signed in prior.</li>
                <li>What this action does behind the hood is move the pool from the PoolArchive table back to the Pool table.</li>
                <li>Does the same to the corresponding SubPool, SubPoolEntry, PeriodPoint, EntryFinance, PoolInvitation, and PoolMessage.</li>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" component="h6">
                Archive Pool
              </Typography>
              <FormButtonSpinnerBase
                isValid={true}
                as={ButtonSmallBase}
                isSubmitting={loadingArchivePool}
                inert="Archive Pool"
                onClick={(e) => {
                  e.preventDefault()
                  archivePool(id, AdminArchivePool)
                    .then(setSnackBarSuccessMessage("Successfully archived this Pool. The page will automatically refresh soon!"))
                    .then(() => window.setTimeout(() => window.location.reload(), 3000))
                }}
                disabled={loadingArchivePool}
                status={""}
              />
              <Typography variant="body2" component="div" color="textSecondary">
                <li>This action fully archives the current pool.</li>
                <li>This action is enabled only for CBS Admins only, and all Admins must be signed in prior.</li>
                <li>What this action does behind the hood is move the pool from the Pool table to the PoolArchive table.</li>
                <li>Does the same to the corresponding SubPool, SubPoolEntry, PeriodPoint, EntryFinance, PoolInvitation, and PoolMessage.</li>
              </Typography>
            </>
          )}

          <Typography variant="h6" component="h6">
            Add Member to Pool
          </Typography>

          <form onSubmit={handleSubmitUpsertEntry(onSubmitUpsertEntry)}>
            <TextField
              className={classes.text}
              error={false}
              id="userLogin"
              label="UserLogin"
              variant="outlined"
              name="userLogin"
              inputRef={register}
            />
            <TextField className={classes.text} error={false} id="custId" label="CustId" variant="outlined" name="custId" inputRef={register} />

            <FormButtonSpinnerBase
              isValid={true}
              as={ButtonSmallBase}
              isSubmitting={loadingAddMember}
              inert="Add Member"
              disabled={loadingAddMember}
              status={""}
              type="submit"
            />
          </form>

          <Typography variant="body2" component="div" color="textSecondary">
            <li>This action is used to add a member to a pool.</li>
            <li>This action is enabled only for CBS Admins only, and all Admins must be signed in prior.</li>
            <li>Make sure the user has a CBSSports account. If not, make one first, then proceed with this action.</li>
            <li>Enter in either the user's userLogin, or their custId (CustomerID).</li>
            <li>What this action does is add a new entry to the pool with the user's information.</li>
          </Typography>
        </CardContent>
      </Card>

      <br />

      <InfoCard
        maintenanceTitle={`To see all Activities of a specific Entry, go to the Entry page, by clicking on your desired Entry id from the table below`}
      />

      <br />

      <DataTable
        {...props}
        poolId={id}
        isDeletable={true}
        isEditable={false}
        title={`${name} Entries`}
        tableNameFromProp="Entry"
        blacklistedColumns={["updatedAt", "createdAt", "anotherColumnToHide"]}
        whitelistedColumns={["id", "displayName", "poolId", "searchName", "userId", "email", "createdAt", "updatedAt"]}
        excludeLayout={true}
        sqlWhereScopes={[`poolId = '${fromGlobalId(id).id}'`]}
      />
    </>
  )
}

export default DefaultPool
